<template>
  <div class="relative">
    <div class="min-h-screen">
      <div>
        <component
          v-for="(blok, index) in header?.decoratedStory.raw"
          :key="index"
          :blok="blok"
          :is="blok.component"
        />
      </div>
      <div
        class="mdplus:items-start w-full mdplus:mx-auto mdplus:max-w-1400 my-8 px-4 md:px-9"
      >
        <slot />
      </div>
    </div>
    <Footer />
    <LazyScrollToTop v-if="scrollButton" />
    <LazyCountrySelector v-if="countrySelector" />
    <ClientOnly>
      <SnackBar />
    </ClientOnly>
    <ClientOnly>
      <ModalCartModal :visibility="showCart" />
    </ClientOnly>
    <ClientOnly>
      <SpinnerLoading />
    </ClientOnly>
    <ClientOnly>
      <ModalEmailVerification />
    </ClientOnly>
    <ClientOnly>
      <ModalCompleteProfile />
    </ClientOnly>
  </div>
</template>

<script setup>
useHead({
  // Classes
  bodyAttrs: { class: { margin: 0 } },
})

const { countrySelector } = useCountrySelector()
const { cart } = useCart()

const scrollButton = ref(false)
const showCart = ref(cart)

const { data: dataHeader } = await useFetch(
  '/api/public/cms/published/global/header'
)

const header = dataHeader.value

onMounted(() => {
  let oldValue = 0
  let newValue = 0

  window.onscroll = () => {
    if (window.pageYOffset < 300) {
      scrollButton.value = false
      return
    }

    newValue = window.pageYOffset
    if (oldValue < newValue) {
      scrollButton.value = false
    } else if (oldValue > newValue) {
      scrollButton.value = true
    }
    oldValue = newValue
  }
})
</script>

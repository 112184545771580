<i18n>
  {
    "es": {
      "COMPLETE_PROFILE": "Completa tu perfil",
      "COMPLETE": "Guardar perfil",
      "COMPLETING": "Guardando perfil",
      "PROFILE_COMPLETED": "Perfil completado",
      "FIRSTNAME_PLACEHOLDER": "Nombre",
      "LASTNAME_PLACEHOLDER": "Apellidos",
      "DAY_PLACEHOLDER": "Día",
      "MONTH_PLACEHOLDER": "Mes",
      "YEAR_PLACEHOLDER": "Año",
      "FIRSTNAME_REQUIRED": "El nombre es obligatorio",
      "FIRSTNAME_LENGTH": "El nombre debe tener al menos 3 caracteres",
      "LASTNAME_REQUIRED": "Los apellidos son obligatorios",
      "LASTNAME_LENGTH": "Los apellidos deben tener al menos 3 caracteres",
      "DAY_REQUIRED": "El día es obligatorio",
      "DAY_NOT_VALID": "El día no es válido",
      "MONTH_REQUIRED": "El mes es obligatorio",
      "MONTH_NOT_VALID": "El mes no es válido",
      "YEAR_REQUIRED": "El año es obligatorio",
      "YEAR_NOT_VALID": "El año no es válido",
      "PHONE_REQUIRED": "El teléfono es obligatorio",
      "MUST_SELECT_ONE_GENDER": 'Debes seleccionar un género',
      "GENDER_REQUIRED": 'El género es requerido',
      "ERROR": "Ha ocurrido un error",
    },
    "en": {
      "COMPLETE_PROFILE": "Complete your profile",
      "COMPLETE": "Save profile",
      "COMPLETING": "Saving profile",
      "PROFILE_COMPLETED": "Profile completed",
      "FIRSTNAME_PLACEHOLDER": "First name",
      "LASTNAME_PLACEHOLDER": "Last name",
      "DAY_PLACEHOLDER": "Day",
      "MONTH_PLACEHOLDER": "Month",
      "YEAR_PLACEHOLDER": "Year",
      "FIRSTNAME_REQUIRED": "First name is required",
      "FIRSTNAME_LENGTH": "First name must have at least 3 characters",
      "LASTNAME_REQUIRED": "Last name is required",
      "LASTNAME_LENGTH": "Last name must have at least 3 characters",
      "DAY_REQUIRED": "Day is required",
      "DAY_NOT_VALID": "Day is not valid",
      "MONTH_REQUIRED": "Month is required",
      "MONTH_NOT_VALID": "Month is not valid",
      "YEAR_REQUIRED": "Year is required",
      "YEAR_NOT_VALID": "Year is not valid",
      "PHONE_REQUIRED": "Phone is required",
      "MUST_SELECT_ONE_GENDER": 'You must select one gender',
      "GENDER_REQUIRED": "Gender is required",
      "ERROR": "An error has occurred",
    },
    "fr": {
      "COMPLETE_PROFILE": "Complétez votre profil",
      "COMPLETE": "Enregistrer le profil",
      "COMPLETING": "Enregistrement du profil",
      "PROFILE_COMPLETED": "Profil complété",
      "FIRSTNAME_PLACEHOLDER": "Prénom",
      "LASTNAME_PLACEHOLDER": "Nom de famille",
      "DAY_PLACEHOLDER": "Jour",
      "MONTH_PLACEHOLDER": "Mois",
      "YEAR_PLACEHOLDER": "Année",
      "FIRSTNAME_REQUIRED": "Le prénom est obligatoire",
      "FIRSTNAME_LENGTH": "Le prénom doit comporter au moins 3 caractères",
      "LASTNAME_REQUIRED": "Le nom de famille est obligatoire",
      "LASTNAME_LENGTH": "Le nom de famille doit comporter au moins 3 caractères",
      "DAY_REQUIRED": "Le jour est obligatoire",
      "DAY_NOT_VALID": "Le jour n'est pas valide",
      "MONTH_REQUIRED": "Le mois est obligatoire",
      "MONTH_NOT_VALID": "Le mois n'est pas valide",
      "YEAR_REQUIRED": "L'année est obligatoire",
      "YEAR_NOT_VALID": "L'année n'est pas valide",
      "PHONE_REQUIRED": "Le téléphone est obligatoire",
      "MUST_SELECT_ONE_GENDER": 'Vous devez sélectionner un genre',
      "ERROR": "Une erreur s'est produite",
    },
    "it": {
      "COMPLETE_PROFILE": "Completa il tuo profilo",
      "COMPLETE": "Salva profilo",
      "COMPLETING": "Salvataggio del profilo",
      "PROFILE_COMPLETED": "Profilo completato",
      "FIRSTNAME_PLACEHOLDER": "Nome",
      "LASTNAME_PLACEHOLDER": "Cognome",
      "DAY_PLACEHOLDER": "Giorno",
      "MONTH_PLACEHOLDER": "Mese",
      "YEAR_PLACEHOLDER": "Anno",
      "FIRSTNAME_REQUIRED": "Il nome è obbligatorio",
      "FIRSTNAME_LENGTH": "Il nome deve avere almeno 3 caratteri",
      "LASTNAME_REQUIRED": "Il cognome è obbligatorio",
      "LASTNAME_LENGTH": "Il cognome deve avere almeno 3 caratteri",
      "DAY_REQUIRED": "Il giorno è obbligatorio",
      "DAY_NOT_VALID": "Il giorno non è valido",
      "MONTH_REQUIRED": "Il mese è obbligatorio",
      "MONTH_NOT_VALID": "Il mese non è valido",
      "YEAR_REQUIRED": "L'anno è obbligatorio",
      "YEAR_NOT_VALID": "L'anno non è valido",
      "PHONE_REQUIRED": "Il telefono è obbligatorio",
      "MUST_SELECT_ONE_GENDER": 'Devi selezionare un genere',
      "ERROR": "Si è verificato un errore",
    },
    "de": {
      "COMPLETE_PROFILE": "Vervollständigen Sie Ihr Profil",
      "COMPLETE": "Profil speichern",
      "COMPLETING": "Profil speichern",
      "PROFILE_COMPLETED": "Profil abgeschlossen",
      "FIRSTNAME_PLACEHOLDER": "Vorname",
      "LASTNAME_PLACEHOLDER": "Nachname",
      "DAY_PLACEHOLDER": "Tag",
      "MONTH_PLACEHOLDER": "Monat",
      "YEAR_PLACEHOLDER": "Jahr",
      "FIRSTNAME_REQUIRED": "Vorname ist erforderlich",
      "FIRSTNAME_LENGTH": "Vorname muss mindestens 3 Zeichen haben",
      "LASTNAME_REQUIRED": "Nachname ist erforderlich",
      "LASTNAME_LENGTH": "Nachname muss mindestens 3 Zeichen haben",
      "DAY_REQUIRED": "Tag ist erforderlich",
      "DAY_NOT_VALID": "Tag ist ungültig",
      "MONTH_REQUIRED": "Monat ist erforderlich",
      "MONTH_NOT_VALID": "Monat ist ungültig",
      "YEAR_REQUIRED": "Jahr ist erforderlich",
      "YEAR_NOT_VALID": "Jahr ist ungültig",
      "PHONE_REQUIRED": "Telefon ist erforderlich",
      "MUST_SELECT_ONE_GENDER": 'Sie müssen ein Geschlecht auswählen',
      "ERROR": "Ein Fehler ist aufgetreten",
    },
    "pt": {
      "COMPLETE_PROFILE": "Complete seu perfil",
      "COMPLETE": "Salvar perfil",
      "COMPLETING": "Salvando perfil",
      "PROFILE_COMPLETED": "Perfil completo",
      "FIRSTNAME_PLACEHOLDER": "Nome",
      "LASTNAME_PLACEHOLDER": "Sobrenome",
      "DAY_PLACEHOLDER": "Dia",
      "MONTH_PLACEHOLDER": "Mês",
      "YEAR_PLACEHOLDER": "Ano",
      "FIRSTNAME_REQUIRED": "Nome é obrigatório",
      "FIRSTNAME_LENGTH": "Nome deve ter pelo menos 3 caracteres",
      "LASTNAME_REQUIRED": "Sobrenome é obrigatório",
      "LASTNAME_LENGTH": "Sobrenome deve ter pelo menos 3 caracteres",
      "DAY_REQUIRED": "Dia é obrigatório",
      "DAY_NOT_VALID": "Dia não é válido",
      "MONTH_REQUIRED": "Mês é obrigatório",
      "MONTH_NOT_VALID": "Mês não é válido",
      "YEAR_REQUIRED": "Ano é obrigatório",
      "YEAR_NOT_VALID": "Ano não é válido",
      "PHONE_REQUIRED": "Telefone é obrigatório",
      "MUST_SELECT_ONE_GENDER": 'Você deve selecionar um gênero',
      "ERROR": "Ocorreu um erro",
    },
  }
</i18n>

<template>
  <ModalCourtain :visibility="completeProfile" @close="cancel">
    <template v-slot:title>
      <div
        class="flex flex-col items-center px-4 justify-center w-full bg-white"
      >
        <h2 class="text-xl md:text-2xl font-bodoni font-bold text-center">
          {{ t('COMPLETE_PROFILE') }}
        </h2>
      </div>
    </template>
    <template v-slot:content>
      <form class="space-y-4 md:max-w-xl md:mt-12 mx-auto py-2.5">
        <div
          v-if="loadingData"
          class="w-full h-60 rounded-lg bg-[#d8d8d8]"
        ></div>
        <LabelAnimationInput
          v-else
          required
          type="text"
          id="firstname"
          name="firstname"
          :placeholder="t('FIRSTNAME_PLACEHOLDER')"
          v-model="formData.firstname"
          @change="v$.firstname.$touch()"
          :valid="!v$.firstname.$invalid || formData.firstname == ''"
        />
        <div
          v-if="loadingData"
          class="w-full h-60 rounded-lg bg-[#d8d8d8]"
        ></div>
        <LabelAnimationInput
          v-else
          required
          type="text"
          id="lastname"
          name="lastname"
          :placeholder="t('LASTNAME_PLACEHOLDER')"
          v-model="formData.lastname"
          @change="v$.lastname.$touch()"
          :valid="!v$.lastname.$invalid || formData.lastname == ''"
        />
        <div
          v-if="loadingData"
          class="flex items-start justify-between space-x-2.5"
        >
          <div class="w-86 h-14 rounded-lg bg-[#d8d8d8]"></div>
          <div class="w-full h-60 rounded-lg bg-[#d8d8d8]"></div>
        </div>
        <PhoneInput v-else v-model="formData.phone" />
        <div class="flex items-center justify-between space-x-2.5">
          <div
            v-if="loadingData"
            class="w-full h-60 rounded-lg bg-[#d8d8d8]"
          ></div>
          <LabelAnimationInput
            v-else
            divClass=" w-full"
            required
            type="number"
            id="dob"
            name="dob"
            :placeholder="t('DAY_PLACEHOLDER')"
            v-model="formData.day"
            min="1"
            max="31"
            @change="v$.day.$touch()"
            :valid="!v$.day.$invalid || formData.day == ''"
          />
          <div
            v-if="loadingData"
            class="w-full h-60 rounded-lg bg-[#d8d8d8]"
          ></div>
          <LabelAnimationInput
            v-else
            divClass=" w-full"
            required
            type="number"
            id="mob"
            name="mob"
            :placeholder="t('MONTH_PLACEHOLDER')"
            v-model="formData.month"
            min="1"
            max="12"
            @change="v$.month.$touch()"
            :valid="!v$.month.$invalid || formData.month == ''"
          />
          <div
            v-if="loadingData"
            class="w-full h-60 rounded-lg bg-[#d8d8d8]"
          ></div>
          <LabelAnimationInput
            v-else
            divClass=" w-full"
            required
            type="number"
            id="yob"
            name="yob"
            :placeholder="t('YEAR_PLACEHOLDER')"
            v-model="formData.year"
            min="1900"
            max="9999"
            @change="v$.year.$touch()"
            :valid="!v$.year.$invalid || formData.year == ''"
          />
        </div>
        <div class="flex flex-wrap items-center gap-4 md:gap-8 mt-2">
          <Checkbox
            v-for="gn in genres"
            :key="gn.value"
            :value="gn.value"
            :text="gn.text"
            @select="formData.gender = gn.value"
            :selected="formData.gender == gn.value"
          />
        </div>
      </form>
    </template>
    <template v-slot:footer>
      <div v-if="loadingData" class="w-full h-60 rounded-lg bg-[#d8d8d8]"></div>
      <button
        v-else
        @click="handleCompleteProfile"
        :aria-label="t('COMPLETE')"
        type="submit"
        :disabled="!buttonEnabled"
        class="uppercase bg-others-black bg-opacity-90 text-white mt-10 w-full rounded h-14 font-bold text-sm hover:animate-[slide-in_0.83s] hover:bg-[200%_100%] hover:bg-[linear-gradient(270deg,#212121_50%,#000_0)] animate-[get-light_0.25s] transition-all duration-200 flex justify-center items-center"
        style="background-size: 200% 100%"
        :class="
          buttonEnabled
            ? 'cursor-pointer opacity-100'
            : 'opacity-50 cursor-not-allowed'
        "
      >
        <img
          v-if="loading"
          src="@/assets/svg/icons/icons-loading.svg"
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        />
        <span v-if="loading">{{ t('COMPLETING') }}</span>
        <span v-else>{{ t('COMPLETE') }}</span>
      </button>
    </template>
  </ModalCourtain>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, or, helpers } from '@vuelidate/validators'

const { completeProfile, hideCompleteProfileModal } = useAfterSignup()
const { t } = useI18n({
  useScope: 'local',
})

const emit = defineEmits(['close'])

const { addMessage } = useSnackBar()
const route = useRoute()
const router = useRouter()
const { locale } = useI18n()
const loading = ref(false)

const { data: customer, loading: loadingData } = await useLazyAsyncData(() =>
  $fetch('/api/account/personaldata', {
    method: 'GET',
  })
)

const formData = ref({
  firstname: '',
  lastname: '',
  phone: '',
  day: '',
  month: '',
  year: '',
  gender: '',
})

const genres = [
  {
    text: 'Mujer',
    value: 'female',
  },
  {
    text: 'Hombre',
    value: 'male',
  },
  {
    text: 'Sin especificar',
    value: 'not-specified',
  },
]

watch(customer, () => {
  const user = customer.value
  formData.value.firstname = user.firstname
  formData.value.lastname = user.lastname
  if (user.phone) {
    formData.value.phone = user.phone
  }

  if (user.dob) {
    const date = stringToDate(user.dob)

    // Obtenemos el día, mes y año
    formData.value.day = date.getDate()
    formData.value.month = date.getMonth() + 1 // Los meses en JavaScript van de 0 a 11
    formData.value.year = date.getFullYear()
  }

  if (user.gender) {
    formData.value.gender = user.gender
  }
})

const rules = computed(() => {
  return {
    firstname: {
      required: helpers.withMessage(t('FIRSTNAME_REQUIRED'), required),
      minLength: helpers.withMessage(t('FIRSTNAME_LENGTH'), minLength(3)),
    },
    lastname: {
      required: helpers.withMessage(t('LASTNAME_REQUIRED'), required),
      minLength: helpers.withMessage(t('LASTNAME_LENGTH'), minLength(3)),
    },
    day: {
      required: helpers.withMessage(t('DAY_REQUIRED'), required),
      shouldBeChecked: helpers.withMessage(t('DAY_NOT_VALID'), (value) =>
        or(...Array.from({ length: 31 }, (_, i) => i + 1))
      ),
    },
    month: {
      required: helpers.withMessage(t('MONTH_REQUIRED'), required),
      shouldBeChecked: helpers.withMessage(t('MONTH_NOT_VALID'), (value) =>
        or(...Array.from({ length: 12 }, (_, i) => i + 1))
      ),
    },
    year: {
      required: helpers.withMessage(t('YEAR_REQUIRED'), required),
      shouldBeChecked: helpers.withMessage(t('YEAR_NOT_VALID'), (value) =>
        or(
          ...Array.from({ length: 122 }, (_, i) => new Date().getFullYear() - i)
        )
      ),
    },
    gender: {
      required: helpers.withMessage(t('GENDER_REQUIRED'), required),
      shouldBeChecked: helpers.withMessage(
        t('MUST_SELECT_ONE_GENDER'),
        (value) => or('female', 'male', 'not-specified')
      ),
    },
    phone: {
      required: helpers.withMessage(t('PHONE_REQUIRED'), required),
    },
  }
})

const v$ = useVuelidate(rules, formData)

const buttonEnabled = computed(() => {
  if (!v$.value) return false
  v$.value.$validate()

  if (v$.value.$error || v$.value.$invalid) return false

  let day
  let month
  let year
  if (customer.value.dob) {
    const date = stringToDate(customer.value.dob)

    // Obtenemos el día, mes y año
    day = date.getDate()
    month = date.getMonth() + 1 // Los meses en JavaScript van de 0 a 11
    year = date.getFullYear()
  }

  const anyChanges =
    formData.value.firstname != customer.value.firstname ||
    formData.value.lastname != customer.value.lastname ||
    formData.value.phone != customer.value.phone ||
    formData.value.day != day ||
    formData.value.month != month ||
    formData.value.year != year

  return anyChanges
})

onMounted(() => {
  // firstInputRef.value.focus()
})

const handleCompleteProfile = async () => {
  v$.value.$validate()
  if (v$.value.$error) {
    const firstError = v$.value.$errors[0]
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: firstError.$message,
    })
    return
  }
  loading.value = true

  // Convertimos los valores a cadenas y los ajustamos si es necesario
  const dayString = formData.value.day.toString().padStart(2, '0')
  const monthString = formData.value.month.toString().padStart(2, '0')

  const { error } = await useAsyncData(
    `updatePersonalData`,
    () =>
      $fetch('/api/account/personaldata/complete', {
        method: 'POST',
        body: {
          firstname: formData.value.firstname,
          lastname: formData.value.lastname,
          dob: `${dayString}/${monthString}/${formData.value.year}`,
          gender: formData.value.gender ?? customer.value.gender,
          phone: formData.value.phone,
        },
      }),
    {}
  )

  loading.value = false

  if (error.value) {
    handleError(error.value)
  } else {
    hideCompleteProfileModal()
    addMessage({
      type: 'success',
      result: 'OK',
      text: t('PROFILE_COMPLETED'),
    })
  }
}

const cancel = () => {
  hideCompleteProfileModal()
}

const handleError = (error) => {
  console.debug(error)
  addMessage({
    type: 'error',
    result: 'ERROR',
    text: t('ERROR'),
  })
}
</script>
